import {useRoute} from "vue-router";
import {onMounted, ref, watch} from "vue";
import erpApi from "@/api/erpApi";


const useBreadcrumbs = () => {
    const route = useRoute();
    const title = ref('');
    const routes = ref([]);
    const menu = ref({});
    const loading = ref(true);


    const getBreadcrumbs = async () => {
        loading.value = true;

        try {
            const context = route.name ? route.name.toString().split('.')[0] : 'home'
            const {data} = await erpApi.get(`/${context}/breadcrumbs`, {
                params: {
                    name: route.name,
                    params: route.params
                }
            })

            title.value = data.title;
            routes.value = data.routes;
            menu.value = data.menu;
        } catch {
            title.value = '';
            routes.value = [];
            menu.value = {};
        } finally {
            loading.value = false
        }
    }

    onMounted(async () => {
        await getBreadcrumbs()
    });

    watch(route, async () => await getBreadcrumbs())

    return {
        loading,
        routes,
        menu,
        title
    }
}

export default useBreadcrumbs;
