<template>
    <nav class="navbar navbar-default navbar-static-top m-b-0">
        <div class="navbar-header">
            <div class="top-left-part">
                <a class="log-a logo" href="javascript:void(0)">
                    <b
                        ><img
                            src="@/assets/images/company.jpg"
                            alt="home"
                            class="logo"
                            @click.prevent="toggleCompaniesList"
                    /></b>
                    <span class="dropdown">
                        <a
                            class="btn btn-block empresas-btn font-20 waves-effect waves-light"
                            href="#"
                            @click.prevent="toggleCompaniesList"
                            v-html="companyName"
                        >
                        </a>
                        <ul
                            class="animated dropdown-menu dropdown-tasks slideInUp menu-empresas"
                            :class="{ show: showCompaniesList }"
                        >
                            <li v-show="!loading" v-for="company in companies" :key="company.id">
                                <img src="@/assets/images/MAKRO-01-1024x648.jpg" class="logo" />
                                <a href="#" v-html="company.name"></a>
                            </li>
                            <li v-show="loading" class="pre-loader-topbar">
                                <div class=""></div>
                                <a href="">Cargando...</a>
                            </li>
                        </ul>
                    </span>
                </a>
            </div>
            <ul class="nav navbar-top-links navbar-left hidden-xs">
                <li class="icon-nav" style="margin-left: 5px">
                    <a href="#" @click.prevent="toggleSidebar" class="sidebartoggler font-20 waves-effect waves-light"
                        ><i :class="{ 'icon-arrow-left-circle': !showSidebar, 'fa fa-bars': showSidebar }"></i
                    ></a>
                </li>
                <li class="icon-nav">
                    <router-link :to="{ name: 'home' }" class="font-20"><i class="fa fa-home" /></router-link>
                </li>
                <li style="padding-top: 3px">
                    <ul class="menu-horizontal" id="list01">
                        <li v-for="topBarOption in topBarOptions" :key="topBarOption.name">
                            <a
                                class="nav-link menu-link-med"
                                :class="{ topMenuSelected: topBarOption.name === topBarOptionSelected }"
                                href="#"
                                @click.prevent="getLeftBarOptions(topBarOption.name)"
                            >
                                {{ topBarOption.title }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li id="iconList02" style="display: none">
                    <ul class="nav navbar-top-links">
                        <li class="dropdown">
                            <a
                                class="font-20 waves-effect waves-light pl-2 pr-2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                href="javascript:void(0);"
                            >
                                <i class="fa fa-chevron-circle-down"></i>
                            </a>
                            <ul
                                class="animated dropdown-menu dropdown-menu-left dropdown-tasks slideInUp"
                                id="list02"
                            ></ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="nav navbar-top-links navbar-right pull-right">
                <li class="dropdown">

                    <a
                        class="font-20 waves-effect waves-light"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        :aria-expanded="showProfileMenu"
                        href="#"
                        @click.prevent="toggleProfileMenu"
                        @blur="hideProfileMenu"
                    >   <h5 class="user-name-nav">{{ username }}</h5>
                        <img src="@/assets/images/user.jpg" class="user-img-nav" />
                        <i class="fa fa-chevron-circle-down i-user-img-nav"></i>
                    </a>
                    <ul
                        class="animated dropdown-menu dropdown-menu-right dropdown-tasks slideInUp"
                        :class="{ show: showProfileMenu }"
                    >
                        <li>
                            <a href="javascript:void(0);"><i class="icon-user fa-fw"></i> Perfil</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);"><i class="icon-drawar fa-fw"></i> Bandeja de entrada</a>
                        </li>
                        <li>
                            <a href="#" @click="onCompanies"><i class="icon-drawar fa-fw"></i> Empresas </a>
                        </li>
                        <hr />
                        <li class="right-side-toggle">
                            <a class="right-side-toggler waves-effect waves-light" href="javascript:void(0)"
                                ><i class="icon-settings fa-fw"></i> Ajuste de sistema</a
                            >
                        </li>
                        <hr />
                        <li>
                            <a href="#" @click.prevent="onLogout">
                                <i class="icon-power fa-fw"></i>
                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import useAuth from "@/modules/auth/composables/useAuth";
import router from "@/router";
import useTopbar from "@/composables/useTopbar";
import useTopbarOptions from "@/composables/useTopbarOptions";
import { nextTick, ref, watch } from "vue";
import $ from 'jquery'
import ocultarMenu from '../../public/top-menu-script'

export default {
    setup() {
        const { logout, username } = useAuth();
        const { showProfileMenu, showCompaniesList, companyName, companies, loading } = useTopbar();
        const { topBarOptions, topBarOptionSelected, getLeftBarOptions } = useTopbarOptions();

        const showSidebar = ref(false);

        //-------ocultar menu por partes-------------------------------
        setTimeout(() => {
            ocultarMenu(screen.width);
            ocultarMenu($('body').width());
        },3000)

        $(window).resize(function () {
            ocultarMenu(screen.width);
        });

        window.onresize = function () {
            ocultarMenu($('body').width());
        };
        //----------------------------------------------------------------

        watch(showSidebar, (value) => {
            if (value) {
                window.$("body").addClass("mini-sidebar");
            } else {
                window.$("body").removeClass("mini-sidebar");
            }
        });

        return {
            username,
            showSidebar,
            showProfileMenu,
            showCompaniesList,
            topBarOptions,
            topBarOptionSelected,
            getLeftBarOptions,
            companyName,
            companies,
            loading,
            toggleProfileMenu: () => (showProfileMenu.value = !showProfileMenu.value),
            hideProfileMenu: () => setTimeout(() => (showProfileMenu.value = false), 250),
            toggleCompaniesList: () => (showCompaniesList.value = !showCompaniesList.value),
            toggleSidebar: () => (showSidebar.value = !showSidebar.value),
            onCompanies: () => {
                router.push({ name: "companies" });
            },
            onLogout: () => {
                logout();
                nextTick(() => router.push({ name: "login" }));
            },
        };
    },
};
</script>

<style scoped>
.topMenuSelected {
    color: white;
}

#list02 > li > a.topMenuSelected {
    color: dodgerblue !important;
}
</style>
