
import useBreadcrumbs from "@/composables/useBreadcrumbs";
import {ref} from "vue";

export default {
    setup() {
        const {title, routes, menu, loading} = useBreadcrumbs()

        const showOptions = ref(false)

        return {
            loading,
            routes,
            menu,
            title,
            showOptions,
            toggleOptions: () => showOptions.value = !showOptions.value,
            hideOptions: () => setTimeout(() => showOptions.value = false, 250)
        }
    }
}
