
import useTopbarOptions from "@/composables/useTopbarOptions";
import { onMounted, Ref, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
    setup() {
        interface BarOption {
            name: string;
            title: string;
            class: string;
        }

        const { topbarMenuName } = useTopbarOptions();

        const leftBarOptions: Ref<BarOption[]> = ref([]);
        const loadingLeftBar: Ref<boolean> = ref(false);
        const route = useRoute();

        const isSelected = (name: string): boolean => {
            return !!route.name && name.indexOf(route.name.toString()) !== -1;
        };

        const populateOptions = (name: string) => {
            loadingLeftBar.value = true;
            if (name === "accounting") {
                leftBarOptions.value = [
                    { name: "accounting_centers", title: "Centros contables", class: "icon-notebook" },
                    { name: "accounting_accounts", title: "Cuentas contables", class: "icon-wallet" },
                    { name: "accounting_configuration.taxes", title: "Configuración", class: "icon-settings" },
                ];
            } else if (name === "inventory") {
                leftBarOptions.value = [
                    { name: "items", title: "Catálogo de ítems", class: " icon-social-dropbox" },
                    { name: "locations", title: "Ubicaciones", class: " icon-location-pin" },
                    { name: "inventory_configuration.categories", title: "Configuración", class: "icon-settings" },
                    { name: "inventory_entries", title: "Entradas de inventario", class: "icon-docs" },
                ];
            } else if (name === "purchase") {
                leftBarOptions.value = [
                    { name: "providers", title: "Proveedores", class: "icon-people" },
                    { name: "petty_cash", title: "Cajas de compras", class: "icon-briefcase" },
                    { name: "provider_advances", title: "Anticipos a proveedores", class: "fa fa-dollar" },
                    { name: "purchase_invoices", title: "Facturas de compras", class: "icon-docs" },
                    { name: "purchase_orders", title: "Órdenes de compras", class: "icon-docs" },
                    { name: "payments", title: "Pagos de compras", class: "fa fa-money" },
                ];
            } else if (name === "sale") {
                leftBarOptions.value = [{ name: "petty_cash", title: "Cajas de ventas", class: "icon-briefcase" }];
            }
            loadingLeftBar.value = false;
        };

        onMounted(() => {
            populateOptions(topbarMenuName.value);
        });

        watch(topbarMenuName, (name: string) => {
            populateOptions(name);
        });

        return {
            leftBarOptions,
            loadingLeftBar,
            isSelected,
        };
    },
};
