import $ from 'jquery'

function validateList02() {

  if ($("#list02 > li").length == 0) {
    $('#iconList02').css('display', 'none');
  } else {
    $('#iconList02').css('display', 'block');
  }
}

function mostrarMenu(minWidth) {
  let liWidthAll = 600;

  $(".menu-horizontal > li").each(function () {
    liWidthAll = liWidthAll + $(this).width();
  });

  $('ul#list02 > li').each(function () {
    const index = $(this).index() + 1;
    liWidthAll = liWidthAll + (100 * index);

    if (liWidthAll < minWidth) {
      $(this).appendTo('#list01');

    }
  });
}

function ocultarMenu(screenWidth) {
  let liWidthAll = 400;
  const lessWidth = 100;
  const minWidth = screenWidth - lessWidth;



  $(".menu-horizontal > li").each(function () {
    liWidthAll = liWidthAll + $(this).width();

    if (liWidthAll > minWidth) {
      $(this).appendTo('#list02');
    }
  });
  mostrarMenu(minWidth)
  validateList02();
}

export default ocultarMenu;
