<template>
    <div class="bg-body font-weight-bold">
        <div id="wrapper">
            <topbar></topbar>
            <leftbar></leftbar>
            <div class="page-wrapper" style="min-height: 875px;">
                <div class="container-fluid main-conta">
                    <div class="pl-1 pr-1">
                        <breadcrumbs></breadcrumbs>
                        <router-view :key="$router.patch + uuid()"></router-view>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer t-a-c">&copy; 2021 Medine</footer>
    </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Leftbar from '@/components/Leftbar.vue'
import Breadcrumbs from '../components/Breadcrumbs'
import { v4 as uuid } from 'uuid'

export default {
    components: {
        Topbar, Leftbar, Breadcrumbs
    },
    setup () {
        return {
            uuid
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../assets/css/style.css";

.footer {
    left: 0;
    right: 0;
}
</style>
