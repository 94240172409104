import {onMounted, Ref, ref, watch} from "vue";
import useAuth from "@/modules/auth/composables/useAuth";
import {Company} from "@/modules/companies/interfaces";
import erpApi from "@/api/erpApi";


const useTopbar = () => {
    const showProfileMenu = ref(false)
    const showCompaniesList = ref(false)

    const {companyName} = useAuth()

    const companies: Ref<Company[]> = ref([])

    const loading = ref(true)

    watch(showCompaniesList, async (val) => {

        if (val) {
            try {
                loading.value = true;
                const response = await erpApi.get('/companies/user')
                const {data} = response
                const {rows} = data

                companies.value = rows.map(({id, cell: {name}}: { id: string, cell: any }) => {
                    return {id, name}
                })
            } finally {
                loading.value = false
            }
        }

    })

    return {
        showProfileMenu,
        showCompaniesList,
        companyName,
        companies,
        loading
    }
}

export default useTopbar;
